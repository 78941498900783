/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {
  circle_id?: string
  limit: number
  offset: number
}

export type InputSchema = undefined

export interface OutputSchema {
  approveInfos?: ApproveInfo[]
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface ApproveInfo {
  actor?: ActorInfo
  inviter?: ActorInfo
  circleId?: number
  circleName?: string
  circlePhoto?: string
  create_dt?: string
  joinReason?: string
  approveType?: string
  approveStatus?: number
  circleUserStatus?: string
  approveId?: number
  type?: 'send' | 'receive'
  isSendCircleOwner?: boolean
  [k: string]: unknown
}

export function isApproveInfo(v: unknown): v is ApproveInfo {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.circleQueryApproveInfos#approveInfo'
  )
}

export function validateApproveInfo(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.circleQueryApproveInfos#approveInfo',
    v,
  )
}

export interface ActorInfo {
  userId?: string
  handle?: string
  displayName?: string
  avatar?: string
  userType?: 'sipz' | 'telegram'
  [k: string]: unknown
}

export function isActorInfo(v: unknown): v is ActorInfo {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.circleQueryApproveInfos#actorInfo'
  )
}

export function validateActorInfo(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.circleQueryApproveInfos#actorInfo',
    v,
  )
}
