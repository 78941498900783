/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

import { Point } from '../common/Point.js';
import { toInt32 } from '../common/utils.js';

/**
 * @module Pattern
 */
function calculateIntersectRatio({ ratios }) {
  return ratios[toInt32(ratios.length / 2)] / 2;
}
class Pattern extends Point {
  #noise;
  #width;
  #height;
  #rect;
  #moduleSize;
  #combined = 1;
  #ratios;
  #intersectRadius;
  static noise(pattern) {
    return pattern.#noise;
  }
  static width(pattern) {
    return pattern.#width;
  }
  static height(pattern) {
    return pattern.#height;
  }
  static combined(pattern) {
    return pattern.#combined;
  }
  static rect(pattern) {
    return pattern.#rect;
  }
  static equals(pattern, x, y, width, height) {
    const { modules } = pattern.#ratios;
    const intersectRadius = pattern.#intersectRadius;
    if (Math.abs(x - pattern.x) <= intersectRadius && Math.abs(y - pattern.y) <= intersectRadius) {
      const moduleSizeThis = pattern.#moduleSize;
      const moduleSize = (width + height) / modules / 2;
      const moduleSizeDiff = Math.abs(moduleSize - moduleSizeThis);
      if (moduleSizeDiff <= 1 || moduleSizeDiff <= moduleSizeThis) {
        return true;
      }
    }
    return false;
  }
  static combine(pattern, x, y, width, height, noise) {
    const combined = pattern.#combined;
    const combinedCombined = combined + 1;
    const combinedX = (pattern.x * combined + x) / combinedCombined;
    const combinedY = (pattern.y * combined + y) / combinedCombined;
    const combinedNoise = (pattern.#noise * combined + noise) / combinedCombined;
    const combinedWidth = (pattern.#width * combined + width) / combinedCombined;
    const combinedHeight = (pattern.#height * combined + height) / combinedCombined;
    const combinedPattern = new Pattern(pattern.#ratios, combinedX, combinedY, combinedWidth, combinedHeight, combinedNoise);
    combinedPattern.#combined = combinedCombined;
    return combinedPattern;
  }
  constructor(ratios, x, y, width, height, noise) {
    super(x, y);
    const { modules } = ratios;
    const widthHalf = width / 2;
    const heightHalf = height / 2;
    const xModuleSize = width / modules;
    const yModuleSize = height / modules;
    const xModuleSizeHalf = xModuleSize / 2;
    const yModuleSizeHalf = yModuleSize / 2;
    const ratio = calculateIntersectRatio(ratios);
    const moduleSize = (xModuleSize + yModuleSize) / 2;
    this.#noise = noise;
    this.#width = width;
    this.#height = height;
    this.#ratios = ratios;
    this.#moduleSize = moduleSize;
    this.#rect = [
      x - widthHalf + xModuleSizeHalf,
      y - heightHalf + yModuleSizeHalf,
      x + widthHalf - xModuleSizeHalf,
      y + heightHalf - yModuleSizeHalf
    ];
    this.#intersectRadius = moduleSize * ratio;
  }
  get moduleSize() {
    return this.#moduleSize;
  }
}

export { Pattern };
