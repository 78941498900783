/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

import { accumulate } from '../common/utils.js';

/**
 * @module PatternRatios
 */
class PatternRatios {
  #modules;
  #ratios;
  constructor(ratios) {
    this.#ratios = ratios;
    this.#modules = accumulate(ratios);
  }
  get modules() {
    return this.#modules;
  }
  get ratios() {
    return this.#ratios;
  }
}
const FINDER_PATTERN_RATIOS = new PatternRatios([1, 1, 3, 1, 1]);
const ALIGNMENT_PATTERN_RATIOS = new PatternRatios([1, 1, 1, 1, 1]);
const ALIGNMENT_PATTERN_LOOSE_MODE_RATIOS = new PatternRatios([1, 1, 1]);

export { ALIGNMENT_PATTERN_LOOSE_MODE_RATIOS, ALIGNMENT_PATTERN_RATIOS, FINDER_PATTERN_RATIOS, PatternRatios };
