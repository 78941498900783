/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

/**
 * @module ECB
 */
class ECB {
  #count;
  #numDataCodewords;
  constructor(count, numDataCodewords) {
    this.#count = count;
    this.#numDataCodewords = numDataCodewords;
  }
  get count() {
    return this.#count;
  }
  get numDataCodewords() {
    return this.#numDataCodewords;
  }
}

export { ECB };
