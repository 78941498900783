/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {}

export interface InputSchema {
  participants: ParticipantObj[]
  [k: string]: unknown
}

/** Store telegram channel info success */
export interface OutputSchema {
  ok?: boolean
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
  qp?: QueryParams
  encoding?: 'application/json'
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface ParticipantObj {
  /** The telegram user id. */
  id?: number
  /** The telegram firstname. */
  username?: string
  /** The telegram lastname. */
  firstname?: string
  /** The telegram lastname. */
  lastname?: string
  /** The telegram user number. */
  phone_number?: string
  telegram_json?: Telegram_json_ref
  photo?: Photo_ref
  [k: string]: unknown
}

export function isParticipantObj(v: unknown): v is ParticipantObj {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.telegramMemberDetail#participantObj'
  )
}

export function validateParticipantObj(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.telegramMemberDetail#participantObj',
    v,
  )
}

export interface Telegram_json_ref {
  [k: string]: unknown
}

export function isTelegram_json_ref(v: unknown): v is Telegram_json_ref {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.telegramMemberDetail#telegram_json_ref'
  )
}

export function validateTelegram_json_ref(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.telegramMemberDetail#telegram_json_ref',
    v,
  )
}

export type Photo_ref = BlobRef
