/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {}

export interface InputSchema {
  users: Users
  [k: string]: unknown
}

export interface OutputSchema {
  ok?: boolean
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
  qp?: QueryParams
  encoding?: 'application/json'
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface Users {
  users?: User[]
  [k: string]: unknown
}

export function isUsers(v: unknown): v is Users {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.tagUsersDelete#Users'
  )
}

export function validateUsers(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.tagUsersDelete#Users', v)
}

export interface User {
  other_id?: string
  other_type?: 'telegram' | 'sipz' | 'twitter'
  tag_id?: number
  [k: string]: unknown
}

export function isUser(v: unknown): v is User {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.tagUsersDelete#user'
  )
}

export function validateUser(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.tagUsersDelete#user', v)
}
