/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

/**
 * @module Point
 */
class Point {
  #x;
  #y;
  constructor(x, y) {
    this.#x = x;
    this.#y = y;
  }
  /**
   * @property x
   * @description Get the x of point.
   */
  get x() {
    return this.#x;
  }
  /**
   * @property y
   * @description Get the y of point.
   */
  get y() {
    return this.#y;
  }
}
function distance(a, b) {
  return Math.sqrt(squaredDistance(a, b));
}
function squaredDistance(a, b) {
  const xDiff = a.x - b.x;
  const yDiff = a.y - b.y;
  return xDiff * xDiff + yDiff * yDiff;
}
function calculateTriangleArea(a, b, c) {
  const { x: ax, y: ay } = a;
  const { x: bx, y: by } = b;
  const { x: cx, y: cy } = c;
  return Math.abs(ax * (by - cy) + bx * (cy - ay) + cx * (ay - by)) / 2;
}

export { Point, calculateTriangleArea, distance, squaredDistance };
