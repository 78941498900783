/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

import { Decoded } from './Decoded.js';
import { decode as decode$1 } from './utils/source.js';
import { BitMatrixParser } from './BitMatrixParser.js';
import { getDataBlocks, correctErrors } from './utils/decoder.js';
import { decode } from '../common/encoding/index.js';

/**
 * @module Decoder
 */
function parse(parser, version, { mask, level }) {
  let offset = 0;
  let corrected = 0;
  parser.unmask(mask);
  const ecBlocks = version.getECBlocks(level);
  const codewords = parser.readCodewords(version, level);
  const blocks = getDataBlocks(codewords, version, level);
  const buffer = new Uint8Array(ecBlocks.numTotalDataCodewords);
  for (const { codewords, numDataCodewords } of blocks) {
    const [bytes, errors] = correctErrors(codewords, numDataCodewords);
    buffer.set(bytes.subarray(0, numDataCodewords), offset);
    corrected += errors;
    offset += numDataCodewords;
  }
  return [buffer, corrected];
}
class Decoder {
  #decode;
  /**
   * @constructor
   * @param options The options of decoder.
   */
  constructor({ decode: decode$1 = decode } = {}) {
    this.#decode = decode$1;
  }
  /**
   * @method decode
   * @description Decode the qrcode matrix.
   * @param matrix The qrcode matrix.
   */
  decode(matrix) {
    let corrected = 0;
    let mirror = false;
    let version;
    let codewords;
    let formatInfo;
    const parser = new BitMatrixParser(matrix);
    try {
      version = parser.readVersion();
      formatInfo = parser.readFormatInfo();
      [codewords, corrected] = parse(parser, version, formatInfo);
    } catch {
      if (formatInfo != null) {
        parser.remask(formatInfo.mask);
      }
      parser.mirror();
      mirror = true;
      version = parser.readVersion();
      formatInfo = parser.readFormatInfo();
      [codewords, corrected] = parse(parser, version, formatInfo);
    }
    return new Decoded(decode$1(codewords, version, this.#decode), version, formatInfo, corrected, mirror);
  }
}

export { Decoder };
