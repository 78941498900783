/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {
  circle_id: number
  source_type: 'telegram' | 'sipz' | 'twitter' | 'all'
}

export type InputSchema = undefined

/** Return circle users. */
export interface OutputSchema {
  users: User[]
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface User {
  source_user_id: string
  source_type: string
  sipz_did?: string
  member_count?: number
  status?: 'join' | 'apply' | 'invite' | 'deny' | 'invite_sipz'
  user_name?: string
  handle?: string
  user_type?: 'sipz' | 'telegram'
  avatar?: {}
  sipz_dids?: string[]
  handles?: string[]
  [k: string]: unknown
}

export function isUser(v: unknown): v is User {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.getCircleUsers#user'
  )
}

export function validateUser(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.getCircleUsers#user', v)
}
