/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

import { Point } from '../common/Point.js';
import { GridSampler } from '../common/GridSampler.js';
import { FinderPatternGroup } from './FinderPatternGroup.js';

/**
 * @module Detected
 */
class Detected {
  #matrix;
  #alignment;
  #finder;
  #transform;
  constructor(matrix, transform, finderPatternGroup, alignmentPattern) {
    const sampler = new GridSampler(matrix, transform);
    const size = FinderPatternGroup.size(finderPatternGroup);
    this.#matrix = matrix;
    this.#transform = transform;
    this.#finder = finderPatternGroup;
    this.#alignment = alignmentPattern;
    this.#matrix = sampler.sample(size, size);
  }
  /**
   * @property matrix
   * @description Get the matrix.
   */
  get matrix() {
    return this.#matrix;
  }
  /**
   * @property finder
   * @description Get the finder pattern.
   */
  get finder() {
    return this.#finder;
  }
  /**
   * @property alignment
   * @description Get the alignment pattern.
   */
  get alignment() {
    return this.#alignment;
  }
  /**
   * @property size
   * @description Get the size.
   */
  get size() {
    return FinderPatternGroup.size(this.#finder);
  }
  /**
   * @property moduleSize
   * @description Get the module size.
   */
  get moduleSize() {
    return FinderPatternGroup.moduleSize(this.#finder);
  }
  /**
   * @method mapping
   * @description Get the mapped point.
   * @param x The x of point.
   * @param y The y of point.
   */
  mapping(x, y) {
    [x, y] = this.#transform.mapping(x, y);
    return new Point(x, y);
  }
}

export { Detected };
