/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

import { Polynomial } from './Polynomial.js';

/**
 * @module GaloisField
 */
class GaloisField {
  #size;
  #one;
  #zero;
  #generator;
  #expTable;
  #logTable;
  constructor(primitive, size, generator) {
    let x = 1;
    const expTable = new Int32Array(size);
    for (let i = 0; i < size; i++) {
      expTable[i] = x;
      // We're assuming the generator alpha is 2.
      x *= 2;
      if (x >= size) {
        x ^= primitive;
        x &= size - 1;
      }
    }
    const logTable = new Int32Array(size);
    for (let i = 0, length = size - 1; i < length; i++) {
      logTable[expTable[i]] = i;
    }
    this.#size = size;
    this.#expTable = expTable;
    this.#logTable = logTable;
    this.#generator = generator;
    this.#one = new Polynomial(this, new Int32Array([1]));
    this.#zero = new Polynomial(this, new Int32Array([0]));
  }
  get size() {
    return this.#size;
  }
  get one() {
    return this.#one;
  }
  get zero() {
    return this.#zero;
  }
  get generator() {
    return this.#generator;
  }
  exp(a) {
    return this.#expTable[a];
  }
  log(a) {
    return this.#logTable[a];
  }
  invert(a) {
    return this.#expTable[this.#size - this.#logTable[a] - 1];
  }
  multiply(a, b) {
    if (a === 0 || b === 0) {
      return 0;
    }
    const logTable = this.#logTable;
    return this.#expTable[(logTable[a] + logTable[b]) % (this.#size - 1)];
  }
  buildPolynomial(degree, coefficient) {
    if (coefficient === 0) {
      return this.#zero;
    }
    const coefficients = new Int32Array(degree + 1);
    coefficients[0] = coefficient;
    return new Polynomial(this, coefficients);
  }
}
const QR_CODE_FIELD_256 = new GaloisField(0x011d, 256, 0);

export { GaloisField, QR_CODE_FIELD_256 };
