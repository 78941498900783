/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

import { FinderPatternGroup } from '../FinderPatternGroup.js';
import { quadrilateralToQuadrilateral } from '../../common/PerspectiveTransform.js';

/**
 * @module transform
 */
function createTransform(finderPatternGroup, alignmentPattern) {
  let bottomRightX;
  let bottomRightY;
  let sourceBottomRightX;
  let sourceBottomRightY;
  const { x: topLeftX, y: topLeftY } = finderPatternGroup.topLeft;
  const { x: topRightX, y: topRightY } = finderPatternGroup.topRight;
  const { x: bottomLeftX, y: bottomLeftY } = finderPatternGroup.bottomLeft;
  const sizeMinusThree = FinderPatternGroup.size(finderPatternGroup) - 3.5;
  if (alignmentPattern != null) {
    bottomRightX = alignmentPattern.x;
    bottomRightY = alignmentPattern.y;
    sourceBottomRightX = sizeMinusThree - 3;
    sourceBottomRightY = sourceBottomRightX;
  } else {
    // Don't have an alignment pattern, just make up the bottom-right point.
    bottomRightX = topRightX + bottomLeftX - topLeftX;
    bottomRightY = topRightY + bottomLeftY - topLeftY;
    sourceBottomRightX = sizeMinusThree;
    sourceBottomRightY = sizeMinusThree;
  }
  return quadrilateralToQuadrilateral(
    3.5,
    3.5,
    sizeMinusThree,
    3.5,
    sourceBottomRightX,
    sourceBottomRightY,
    3.5,
    sizeMinusThree,
    topLeftX,
    topLeftY,
    topRightX,
    topRightY,
    bottomRightX,
    bottomRightY,
    bottomLeftX,
    bottomLeftY
  );
}

export { createTransform };
