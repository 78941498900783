/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

import { Charset } from '../Charset.js';

/**
 * @module index
 */
function getUnicodeCodes(content, maxCode) {
  const bytes = [];
  for (const character of content) {
    const code = character.codePointAt(0);
    // If gt max code, push "?".
    bytes.push(code == null || code > maxCode ? 63 : code);
  }
  return new Uint8Array(bytes);
}
function encode(content, charset) {
  switch (charset) {
    case Charset.ASCII:
      return getUnicodeCodes(content, 0x7f);
    case Charset.ISO_8859_1:
      return getUnicodeCodes(content, 0xff);
    case Charset.UTF_8:
      return new TextEncoder().encode(content);
    default:
      throw Error(`built-in encode not support charset: ${charset.label}`);
  }
}
function decode(bytes, charset) {
  try {
    return new TextDecoder(charset.label).decode(bytes);
  } catch {
    throw Error(`built-in decode not support charset: ${charset.label}`);
  }
}

export { decode, encode };
