/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {
  dids: string[]
  limit?: number
  offset?: number
}

export type InputSchema = undefined

/** getNotificationCount result. */
export interface OutputSchema {
  notificationCounts: NotificationCount[]
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface NotificationCount {
  did?: string
  count?: number
  reason?: string
  postUri?: string
  record?: string
  [k: string]: unknown
}

export function isNotificationCount(v: unknown): v is NotificationCount {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.getNotificationCount#notificationCount'
  )
}

export function validateNotificationCount(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.getNotificationCount#notificationCount',
    v,
  )
}
