/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {
  /** circle_id */
  circle_id: number
}

export type InputSchema = undefined

export interface OutputSchema {
  /** circle_id */
  circle_id?: string
  circle_name?: string
  circle_type?: number
  creator?: string
  can_manage?: boolean
  description?: string
  is_public?: boolean
  /** The circle photo. */
  photo?: string
  /** The circle memberCount. */
  member_count?: number
  /** Whether in circle. */
  in_circle?: boolean
  /** The caller's status in circle. */
  status?: string
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}
