/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

/**
 * @module ECBlocks
 */
class ECBlocks {
  #ecBlocks;
  #numTotalCodewords;
  #numTotalECCodewords;
  #numTotalDataCodewords;
  #numECCodewordsPerBlock;
  constructor(numECCodewordsPerBlock, ...ecBlocks) {
    let numBlocks = 0;
    let numTotalDataCodewords = 0;
    for (const { count, numDataCodewords } of ecBlocks) {
      numBlocks += count;
      numTotalDataCodewords += numDataCodewords * count;
    }
    const numTotalECCodewords = numECCodewordsPerBlock * numBlocks;
    this.#ecBlocks = ecBlocks;
    this.#numTotalECCodewords = numTotalECCodewords;
    this.#numTotalDataCodewords = numTotalDataCodewords;
    this.#numECCodewordsPerBlock = numECCodewordsPerBlock;
    this.#numTotalCodewords = numTotalDataCodewords + numTotalECCodewords;
  }
  get ecBlocks() {
    return this.#ecBlocks;
  }
  get numTotalCodewords() {
    return this.#numTotalCodewords;
  }
  get numTotalECCodewords() {
    return this.#numTotalECCodewords;
  }
  get numTotalDataCodewords() {
    return this.#numTotalDataCodewords;
  }
  get numECCodewordsPerBlock() {
    return this.#numECCodewordsPerBlock;
  }
}

export { ECBlocks };
