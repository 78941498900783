/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

/**
 * @module Decoded
 */
class Decoded {
  #mask;
  #level;
  #mirror;
  #version;
  #corrected;
  #metadata;
  constructor(metadata, version, { mask, level }, corrected, mirror) {
    this.#mask = mask;
    this.#level = level;
    this.#mirror = mirror;
    this.#version = version;
    this.#metadata = metadata;
    this.#corrected = corrected;
  }
  /**
   * @property mask
   * @description Get the mask of qrcode.
   */
  get mask() {
    return this.#mask;
  }
  /**
   * @property level
   * @description Get the error correction level of qrcode.
   */
  get level() {
    return this.#level.name;
  }
  /**
   * @property version
   * @description Get the version of qrcode.
   */
  get version() {
    return this.#version.version;
  }
  /**
   * @property mirror
   * @description Get the mirror of qrcode.
   */
  get mirror() {
    return this.#mirror;
  }
  /**
   * @property content
   * @description Get the content of qrcode.
   */
  get content() {
    return this.#metadata.content;
  }
  /**
   * @property corrected
   * @description Get the corrected of qrcode.
   */
  get corrected() {
    return this.#corrected;
  }
  /**
   * @property symbology
   * @description Get the symbology of qrcode.
   */
  get symbology() {
    return this.#metadata.symbology;
  }
  /**
   * @property fnc1
   * @description Get the fnc1 of qrcode.
   */
  get fnc1() {
    return this.#metadata.fnc1;
  }
  /**
   * @property codewords
   * @description Get the codewords of qrcode.
   */
  get codewords() {
    return this.#metadata.codewords;
  }
  /**
   * @property structured
   * @description Get the structured of qrcode.
   */
  get structured() {
    return this.#metadata.structured;
  }
}

export { Decoded };
