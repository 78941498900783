/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {
  includeUnbindTgUser?: boolean
}

export type InputSchema = undefined

/** Query friend recommends result */
export interface OutputSchema {
  recommends: FriendRecommend[]
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface FriendRecommend {
  /** The user's DID. */
  userId?: string
  handle?: string
  displayName?: string
  avatar?: string
  source?: string
  userType?: 'sipz' | 'telegram'
  mutual?: 0 | 1
  isPending?: boolean
  [k: string]: unknown
}

export function isFriendRecommend(v: unknown): v is FriendRecommend {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.friendQueryRecommends#friendRecommend'
  )
}

export function validateFriendRecommend(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.friendQueryRecommends#friendRecommend',
    v,
  )
}
