/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {}

export type InputSchema = undefined

export interface OutputSchema {
  task?: Task_ref[]
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface Task_ref {
  task_id: number
  task_name: string
  description: string
  status: number
  money?: string
  sort?: number
  error?: string
  [k: string]: unknown
}

export function isTask_ref(v: unknown): v is Task_ref {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.getDailyTask#task_ref'
  )
}

export function validateTask_ref(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.getDailyTask#task_ref', v)
}
