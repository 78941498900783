/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'
import * as ComAtprotoServerDefs from './defs'

export interface QueryParams {
  /** the number of pages,from 1 */
  limit?: number
  offset?: number
  /** circle id */
  circleId?: number
  tagIds?: string
  q?: string
}

export type InputSchema = undefined

export interface OutputSchema {
  friends?: SpizFriendInfo[]
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface SpizFriendInfo {
  /** The user's DID. */
  did: string
  handle: string
  displayName?: string
  avatar?: string
  tags?: ComAtprotoServerDefs.Tag[]
  status?: number
  isBandTg?: boolean
  [k: string]: unknown
}

export function isSpizFriendInfo(v: unknown): v is SpizFriendInfo {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.getSpizFriends#spizFriendInfo'
  )
}

export function validateSpizFriendInfo(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.getSpizFriends#spizFriendInfo',
    v,
  )
}
