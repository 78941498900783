/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

import { charAt } from '../utils.js';

/**
 * @module utils
 */
function getMappingFromCharacters(characters) {
  let code = 0;
  const mapping = new Map();
  for (const character of characters) {
    mapping.set(character, code++);
  }
  return mapping;
}
function getMappingFromEncodingRanges(label, ...ranges) {
  const bytes = [];
  const codes = [];
  const mapping = new Map();
  const decoder = new TextDecoder(label, { fatal: true });
  for (const [start, end] of ranges) {
    for (let code = start; code <= end; code++) {
      // Now only support two bytes characters.
      bytes.push((code >> 8) & 0xff, code & 0xff);
      // Cache the codes.
      codes.push(code);
    }
  }
  const { length } = codes;
  const characters = decoder.decode(new Uint8Array(bytes));
  for (let i = 0; i < length; i++) {
    const character = charAt(characters, i);
    if (!mapping.has(character)) {
      mapping.set(character, codes[i]);
    }
  }
  return mapping;
}
function getSerialEncodinRanges(start, end, offsets, delta = 256) {
  const count = offsets.length - 1;
  const ranges = [];
  for (let i = start; i < end; ) {
    for (let j = 0; j < count; j += 2) {
      ranges.push([i + offsets[j], i + offsets[j + 1]]);
    }
    i += delta;
  }
  return ranges;
}

export { getMappingFromCharacters, getMappingFromEncodingRanges, getSerialEncodinRanges };
