/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

import { toInt32 } from './utils.js';

/**
 * @module BitMatrix
 */
class BitMatrix {
  #width;
  #height;
  #rowSize;
  #bits;
  /**
   * @constructor
   * @param width The width of the matrix.
   * @param height The height of the matrix.
   * @param bits The initial bits of the matrix.
   */
  constructor(width, height, bits) {
    const rowSize = Math.ceil(width / 32);
    const bitsCapacity = rowSize * height;
    this.#width = width;
    this.#height = height;
    this.#rowSize = rowSize;
    if (bits instanceof Int32Array) {
      if (bits.length !== bitsCapacity) {
        throw new Error(`matrix bits capacity mismatch: ${bitsCapacity}`);
      }
      this.#bits = bits;
    } else {
      this.#bits = new Int32Array(bitsCapacity);
    }
  }
  #offset(x, y) {
    return y * this.#rowSize + toInt32(x / 32);
  }
  /**
   * @property width
   * @description The width of the matrix.
   */
  get width() {
    return this.#width;
  }
  /**
   * @property height
   * @description The height of the matrix.
   */
  get height() {
    return this.#height;
  }
  /**
   * @method set
   * @description Set the bit value of the specified coordinate.
   * @param x The x coordinate.
   * @param y The y coordinate.
   */
  set(x, y) {
    const offset = this.#offset(x, y);
    this.#bits[offset] |= 1 << (x & 0x1f);
  }
  /**
   * @method get
   * @description Get the bit value of the specified coordinate.
   * @param x The x coordinate.
   * @param y The y coordinate.
   */
  get(x, y) {
    const offset = this.#offset(x, y);
    return (this.#bits[offset] >>> (x & 0x1f)) & 0x01;
  }
  flip(x, y) {
    if (x != null && y != null) {
      const offset = this.#offset(x, y);
      this.#bits[offset] ^= 1 << (x & 0x1f);
    } else {
      const bits = this.#bits;
      const { length } = bits;
      for (let i = 0; i < length; i++) {
        bits[i] = ~bits[i];
      }
    }
  }
  /**
   * @method clone
   * @description Clone the bit matrix.
   */
  clone() {
    return new BitMatrix(this.#width, this.#height, new Int32Array(this.#bits));
  }
  /**
   * @method setRegion
   * @description Set the bit value of the specified region.
   * @param left The left coordinate.
   * @param top The top coordinate.
   * @param width The width to set.
   * @param height The height to set.
   */
  setRegion(left, top, width, height) {
    const bits = this.#bits;
    const right = left + width;
    const bottom = top + height;
    const rowSize = this.#rowSize;
    for (let y = top; y < bottom; y++) {
      const offset = y * rowSize;
      for (let x = left; x < right; x++) {
        bits[offset + toInt32(x / 32)] |= 1 << (x & 0x1f);
      }
    }
  }
}

export { BitMatrix };
