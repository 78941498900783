/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

/**
 * @module Mode
 */
const VALUES_TO_MODE = new Map();
function fromModeBits(bits) {
  const mode = VALUES_TO_MODE.get(bits);
  if (mode != null) {
    return mode;
  }
  throw new Error('illegal mode bits');
}
class Mode {
  #bits;
  #characterCountBitsSet;
  static TERMINATOR = new Mode([0, 0, 0], 0x00);
  static NUMERIC = new Mode([10, 12, 14], 0x01);
  static ALPHANUMERIC = new Mode([9, 11, 13], 0x02);
  static STRUCTURED_APPEND = new Mode([0, 0, 0], 0x03);
  static BYTE = new Mode([8, 16, 16], 0x04);
  static ECI = new Mode([0, 0, 0], 0x07);
  static KANJI = new Mode([8, 10, 12], 0x08);
  static FNC1_FIRST_POSITION = new Mode([0, 0, 0], 0x05);
  static FNC1_SECOND_POSITION = new Mode([0, 0, 0], 0x09);
  static HANZI = new Mode([8, 10, 12], 0x0d);
  constructor(characterCountBitsSet, bits) {
    this.#bits = bits;
    this.#characterCountBitsSet = new Int32Array(characterCountBitsSet);
    VALUES_TO_MODE.set(bits, this);
  }
  get bits() {
    return this.#bits;
  }
  getCharacterCountBits({ version }) {
    let offset;
    if (version <= 9) {
      offset = 0;
    } else if (version <= 26) {
      offset = 1;
    } else {
      offset = 2;
    }
    return this.#characterCountBitsSet[offset];
  }
}

export { Mode, fromModeBits };
