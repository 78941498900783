/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

import { toInt32 } from './utils.js';
import { BitMatrix } from './BitMatrix.js';

/**
 * @module GridSampler
 */
class GridSampler {
  #matrix;
  #transform;
  constructor(matrix, transform) {
    this.#matrix = matrix;
    this.#transform = transform;
  }
  sample(width, height) {
    const matrix = this.#matrix;
    const matrixWidth = matrix.width;
    const transform = this.#transform;
    const matrixHeight = matrix.height;
    const bits = new BitMatrix(width, height);
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const [mappingX, mappingY] = transform.mapping(x + 0.5, y + 0.5);
        const offsetX = toInt32(mappingX);
        const offsetY = toInt32(mappingY);
        if (
          // Assert axis.
          offsetX >= 0 &&
          offsetY >= 0 &&
          offsetX < matrixWidth &&
          offsetY < matrixHeight &&
          matrix.get(offsetX, offsetY)
        ) {
          bits.set(x, y);
        }
      }
    }
    return bits;
  }
}

export { GridSampler };
