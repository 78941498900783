/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

import { Point } from './Point.js';
import { toInt32 } from './utils.js';

/**
 * @module PlotLine
 */
// Mild variant of Bresenham's algorithm.
// see https://en.wikipedia.org/wiki/Bresenham%27s_line_algorithm
class PlotLine {
  #to;
  #from;
  #limit;
  #steep;
  #step;
  #delta;
  constructor(from, to) {
    let toX = toInt32(to.x);
    let toY = toInt32(to.y);
    let fromX = toInt32(from.x);
    let fromY = toInt32(from.y);
    const steep = Math.abs(toY - fromY) > Math.abs(toX - fromX);
    // Steep line.
    if (steep) {
      [fromX, fromY, toX, toY] = [fromY, fromX, toY, toX];
    }
    const stepX = fromX < toX ? 1 : -1;
    this.#steep = steep;
    this.#limit = toX + stepX;
    this.#to = new Point(toX, toY);
    this.#from = new Point(fromX, fromY);
    this.#step = [stepX, fromY < toY ? 1 : -1];
    this.#delta = [Math.abs(toX - fromX), Math.abs(toY - fromY)];
  }
  *points() {
    const limit = this.#limit;
    const steep = this.#steep;
    const { y: toY } = this.#to;
    const [stepX, stepY] = this.#step;
    const [deltaX, deltaY] = this.#delta;
    const { x: fromX, y: fromY } = this.#from;
    let error = toInt32(-deltaX / 2);
    // Loop up until x === toX, but not beyond.
    for (let x = fromX, y = fromY; x !== limit; x += stepX) {
      yield [steep ? y : x, steep ? x : y];
      error += deltaY;
      if (error > 0) {
        if (y === toY) {
          break;
        }
        y += stepY;
        error -= deltaX;
      }
    }
  }
}

export { PlotLine };
