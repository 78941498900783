/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

/**
 * @module Charset
 */
const VALUES_TO_CHARSET = new Map();
function fromCharsetValue(value) {
  const charset = VALUES_TO_CHARSET.get(value);
  if (charset) {
    return charset;
  }
  throw Error('illegal charset value');
}
class Charset {
  #label;
  #values;
  // See: https://en.wikipedia.org/wiki/Extended_Channel_Interpretation
  static CP437 = new Charset('cp437', 2, 0);
  static ISO_8859_1 = new Charset('iso-8859-1', 3, 1);
  static ISO_8859_2 = new Charset('iso-8859-2', 4);
  static ISO_8859_3 = new Charset('iso-8859-3', 5);
  static ISO_8859_4 = new Charset('iso-8859-4', 6);
  static ISO_8859_5 = new Charset('iso-8859-5', 7);
  static ISO_8859_6 = new Charset('iso-8859-6', 8);
  static ISO_8859_7 = new Charset('iso-8859-7', 9);
  static ISO_8859_8 = new Charset('iso-8859-8', 10);
  static ISO_8859_9 = new Charset('iso-8859-9', 11);
  static ISO_8859_10 = new Charset('iso-8859-10', 12);
  static ISO_8859_11 = new Charset('iso-8859-11', 13);
  static ISO_8859_13 = new Charset('iso-8859-13', 15);
  static ISO_8859_14 = new Charset('iso-8859-14', 16);
  static ISO_8859_15 = new Charset('iso-8859-15', 17);
  static ISO_8859_16 = new Charset('iso-8859-16', 18);
  static SHIFT_JIS = new Charset('shift-jis', 20);
  static CP1250 = new Charset('cp1250', 21);
  static CP1251 = new Charset('cp1251', 22);
  static CP1252 = new Charset('cp1252', 23);
  static CP1256 = new Charset('cp1256', 24);
  static UTF_16BE = new Charset('utf-16be', 25);
  static UTF_8 = new Charset('utf-8', 26);
  static ASCII = new Charset('ascii', 27);
  static BIG5 = new Charset('big5', 28);
  static GB2312 = new Charset('gb2312', 29);
  static EUC_KR = new Charset('euc-kr', 30);
  static GBK = new Charset('gbk', 31);
  static GB18030 = new Charset('gb18030', 32);
  static UTF_16LE = new Charset('utf-16le', 33);
  static UTF_32BE = new Charset('utf-32be', 34);
  static UTF_32LE = new Charset('utf-32le', 35);
  static ISO_646_INV = new Charset('iso-646-inv', 170);
  static BINARY = new Charset('binary', 899);
  /**
   * @constructor
   * @param label The label of charset.
   * @param values The values of charset.
   */
  constructor(label, ...values) {
    this.#label = label;
    this.#values = Object.freeze(values);
    for (const value of values) {
      if (value >= 0 && value <= 999999 && Number.isInteger(value)) {
        VALUES_TO_CHARSET.set(value, this);
      } else {
        throw new Error('illegal extended channel interpretation value');
      }
    }
  }
  /**
   * @property label
   * @description Get the label of charset.
   */
  get label() {
    return this.#label;
  }
  /**
   * @property values
   * @description Get the values of charset.
   */
  get values() {
    return this.#values;
  }
}

export { Charset, fromCharsetValue };
