/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {}

export type InputSchema = undefined

/** Query account list result */
export interface OutputSchema {
  emailInfo?: EmailInfo
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface EmailInfo {
  email?: string
  company?: string
  companyId?: string
  jobType?: number
  status?: number
  [k: string]: unknown
}

export function isEmailInfo(v: unknown): v is EmailInfo {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.getUserEmail#emailInfo'
  )
}

export function validateEmailInfo(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.getUserEmail#emailInfo', v)
}
