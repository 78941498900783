/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface Record {
  /** create user did. */
  createDid?: string
  /** AT-URI of the subject (eg, a post record). */
  uri: string
  /** Bet or Bet-ex Contract transaction hash. */
  transId: string
  /** Contract method identity. */
  method: 'insider-bet.create' | 'insider-bet.bet' | 'insider-bet.claim-all'
  /** Payer account. */
  payer?: string
  /** Payer wallet address. */
  payerWalletAddress?: string
  /** Currency like USDC. */
  currency?: string
  /** Transaction amount. */
  amount?: number
  createdAt?: string
  [k: string]: unknown
}

export function isRecord(v: unknown): v is Record {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    (v.$type === 'app.bsky.feed.bet#main' || v.$type === 'app.bsky.feed.bet')
  )
}

export function validateRecord(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.feed.bet#main', v)
}
