/**
 * @module QRCode
 * @package @nuintun/qrcode
 * @license MIT
 * @version 4.1.5
 * @author nuintun <nuintun@qq.com>
 * @description A pure JavaScript QRCode encode and decode library.
 * @see https://github.com/nuintun/qrcode#readme
 */

/**
 * @module constants
 */
const RADIAN = Math.PI / 180;
// Diff pattern.
const DIFF_PATTERN_RATIO = 0.625;
const DIFF_PATTERN_ALLOWANCE = 0.5;
// Diff module size.
const DIFF_MODULE_SIZE_RATIO = 0.5;
// Top left min and max angle.
const MIN_TOP_LEFT_ANGLE = RADIAN * 40;
const MAX_TOP_LEFT_ANGLE = RADIAN * 140;

export { DIFF_MODULE_SIZE_RATIO, DIFF_PATTERN_ALLOWANCE, DIFF_PATTERN_RATIO, MAX_TOP_LEFT_ANGLE, MIN_TOP_LEFT_ANGLE };
