"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: () => {
        return "Browser";
    },
    release: () => {
        return "1.0";
    },
};
